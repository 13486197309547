<template>
  <b-container class="phonebook">
    <h1>Liste des utilisateurs</h1>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Filtrer par établissement"
          label-cols="4"
          content-cols="8"
        >
          <b-form-select
            v-model="establishmentsFilter"
            :options="$store.state.establishments"
          ></b-form-select
        ></b-form-group>
      </b-col>
      <b-col></b-col>
      <b-col><b-button to="/user">Ajouter un utilisateur</b-button> </b-col>
    </b-row>
    <br />
    <b-overlay
      :show="$store.state.isBusy"
      variant="transparent"
      blur="2px"
      opacity="0.85"
    >
      <b-table
        small
        head-variant="dark"
        :items="$store.state.users"
        :fields="fields"
        :filter="filters.criteria"
        :filter-included-fields="filters.fields"
      >
        <template #cell(active)="data">
          <b-form-checkbox
            @change="setActive(data.item.id, data.value)"
            v-model="data.value"
            v-bind:id="data.index.toString()"
          >
          </b-form-checkbox>
        </template>
        <template #cell(establishment)="data">
          {{ data.value.name }}
        </template>
        <template #cell(isOperator)="data">
          {{ operator2human[data.value] }}
        </template>
        <template #cell(createdAt)="data">
          {{ getDate(data.value) }}
        </template>
        <template #cell(id)="data">
          <b-icon-pencil-fill
            @click="$router.push(`/user?id=${data.value}`)"
          ></b-icon-pencil-fill>
        </template> </b-table
    ></b-overlay>
    <b-button v-if="$auth.isAuthenticated" @click="logout()"
      >Se déconnecter</b-button
    ></b-container
  >
</template>
<script>
  import dayjs from 'dayjs'
  import fetch from 'cross-fetch'
  import { BIconPencilFill } from 'bootstrap-vue'
  import { getInstance } from '@/auth'
  export default {
    name: 'Phonebook',
    components: { BIconPencilFill },
    data: () => ({
      isBusy: true,
      onlyActive: false,
      establishmentsFilter: '',
      filters: { criteria: null, fields: [] },
      fields: [
        { key: 'active', label: 'Actif', sortable: true },
        { key: 'establishment', label: 'Établissement', sortable: true },
        { key: 'title', label: 'Titre' },
        { key: 'firstName', label: 'Prénom' },
        { key: 'lastName', label: 'Nom' },
        { key: 'email', label: 'Email' },
        { key: 'isOperator', label: 'Droits' },
        { key: 'createdAt', label: 'Créé le', sortable: true },
        { key: 'id', label: '' },
      ],
      operator2human: {
        true: 'Opérateur',
        false: 'Professionnel de santé',
      },
    }),
    async created() {
      if (1 != this.$route.query.dont) {
        let instance = getInstance()
        this.$auth.token = await instance.getTokenSilently()
        this.$store.dispatch('initEstablishment', this.$auth.token)
        this.$store.dispatch('fetchUsers', this.$auth.token)
      }
    },
    watch: {
      onlyActive(val) {
        if (val) {
          this.filters.criteria = 'true'
          this.filters.fields = ['active']
        } else {
          this.filters.criteria = null
          this.filters.fields = []
        }
      },
      establishmentsFilter(val) {
        if (null == val) {
          this.filters.criteria = null
          this.filters.fields = []
        } else {
          this.filters.criteria = val.toString()
          this.filters.fields = ['establishment']
        }
      },
    },
    methods: {
      async setActive(id, active) {
        this.$set(
          this.$store.state.users.find((u) => id == u.id),
          'active',
          active
        )
        this.isBusy = true
        let instance = getInstance()
        this.$auth.token = await instance.getTokenSilently()
        await fetch('/api/user', {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${this.$auth.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id,
            patch: { active },
          }),
        })
        this.isBusy = false
      },
      getDate(val) {
        return dayjs(val).format('DD/MM/YYYY')
      },
      async logout() {
        await this.$auth.logout()
        this.$router.push({ path: '/' })
      },
    },
  }
</script>
