<template>
  <b-container class="User">
    <h1>{{ title }}</h1>
    <b-overlay :show="isBusy" variant="transparent" blur="2px" opacity="0.85">
      <b-row>
        <b-col sm="2">
          <b-form-select
            v-model="record.title"
            :options="titleList"
            :state="null != record.title"
          ></b-form-select>
        </b-col>
        <b-col sm="5">
          <b-form-input
            v-model="record.firstName"
            placeholder="Prénom"
            :state="null != record.firstName"
          ></b-form-input>
        </b-col>
        <b-col sm="5">
          <b-form-input
            v-model="record.lastName"
            placeholder="Nom"
            :state="null != record.lastName"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-input
            v-model="record.phone"
            placeholder="Téléphone"
          ></b-form-input>
        </b-col>
        <b-col sm="6">
          <b-form-input
            type="email"
            v-model="record.email"
            placeholder="Email"
            :disabled="null != $route.query.id"
            :formatter="(v) => v.toLowerCase()"
            :state="null != record.email"
          ></b-form-input>
        </b-col>
        <b-col sm="3">
          <b-form-input
            type="password"
            v-model="record.clearpassword"
            placeholder="Mot de passe"
            :state="null != record.clearpassword"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-select
            v-model="record.establishmentId"
            :options="$store.state.establishments"
            :state="null != record.establishmentId"
          ></b-form-select>
        </b-col>
        <b-col sm="3">
          <b-form-select
            v-model="record.role"
            :options="roleList"
            :state="null != record.role"
          ></b-form-select>
        </b-col>
        <b-col sm="3">
          <b-form-select
            v-model="record.isOperator"
            :options="operatorList"
          ></b-form-select>
        </b-col>
        <b-col sm="3">
          <b-form-select
            v-model="record.active"
            :options="activeList"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox v-model="legal"
            >En créant cet utilisateur j’atteste qu’il correspond à une personne
            physique qui a été formée à l’usage des outils Magentine.
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button variant="secondary" to="/phonebook">Annuler</b-button>&nbsp;
      <b-button
        v-if="legal"
        variant="primary"
        :disabled="isBusy"
        @click="submit"
        >Valider</b-button
      >
    </b-overlay>
  </b-container>
</template>
<script>
  import { getInstance } from '@/auth'
  import fetch from 'cross-fetch'
  export default {
    name: 'User',
    data: () => ({
      title: "Ajout d'un utilisateur",
      roleList: [
        { value: null, text: 'Choix du role' },
        { value: 'p_t', text: 'Préleveur Testeur' },
        { value: 'c', text: 'Controleur' },
        { value: 't', text: 'Testeur' },
        { value: 'p', text: 'Préleveur' },
      ],
      titleList: [{ value: null, text: 'Titre' }, 'Mme', 'M.', 'Dr.'],
      operatorList: [
        { value: true, text: 'Opérateur' },
        { value: false, text: 'Professionnel de santé' },
      ],
      activeList: [
        { value: true, text: 'Actif' },
        { value: false, text: 'Inactif' },
      ],
      record: {
        id: null,
        email: null,
        role: 'p_t',
        admin: false,
        title: null,
        firstName: null,
        lastName: null,
        clearpassword: null,
        reader: 'manuel',
        phone: null,
        establishmentId: null,
        active: true,
        isOperator: true,
      },
      isBusy: false,
      legal: false,
    }),
    created() {
      if (null != this.$route.query.id) {
        this.title = "Modification d'un utilisateur"
        const record = this.$store.state.users.find(
          (u) => this.$route.query.id == u.id
        )
        delete record.establishment
        Object.assign(this.record, record)
      } else {
        if (this.$store.state.establishments.length < 1) {
          this.$router.push('/phonebook')
        }
      }
    },
    methods: {
      async submit() {
        if (null != this.$route.query.id) {
          const original = this.$store.state.users.find(
            (u) => this.$route.query.id == u.id
          )
          let patch = {}
          for (const key in this.record) {
            if (original[key] != this.record[key]) {
              patch[key] = this.record[key]
            }
          }
          if (null != this.record.clearpassword) {
            patch.clearpassword = this.record.clearpassword
          }
          if (0 < Object.keys(patch).length) {
            this.isBusy = true
            let instance = getInstance()
            this.$auth.token = await instance.getTokenSilently()
            await fetch('/api/user', {
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${this.$auth.token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ id: this.record.id, patch }),
            })
            this.isBusy = false
            this.$router.push('/phonebook')
          }
        } else {
          if (
            null != this.record.email &&
            null != this.record.clearpassword &&
            null != this.record.establishmentId &&
            null != this.record.role
          ) {
            this.isBusy = true
            let instance = getInstance()
            this.$auth.token = await instance.getTokenSilently()
            await fetch('/api/user', {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${this.$auth.token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(this.record),
            })
            this.isBusy = false
            this.$router.push('/phonebook')
          }
        }
      },
    },
  }
</script>
<style scoped>
  .row {
    margin-top: 2ex;
    margin-bottom: 2ex;
  }
</style>
